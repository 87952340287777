import React, {useEffect, useState} from "react";
import Header from "./Header";
import Buttons from "./Buttons";
import Question from "./Question";
import {useParams} from "react-router-dom";
import {firestore} from "../firebase/clientApp";
import ApplyForm from "./ApplyForm";
import {Box, Button, CircularProgress, Stack, TextField, Typography} from "@mui/material";
import LogoBox from "./LogoBox";
import Agenda from "./Agenda";
import {useDocument} from "react-firebase-hooks/firestore";
import {DisplayName} from "./DisplayName";
import {useLocalStorage} from "../functions/customHook/useLocalStorage";

export default function VoteContent({user, event, loading, displayName, setDisplayName}) {
    const {id} = useParams()
    const [idPlaying, setIdPlaying] = useState('')
    const [eventPlaying, setEventPlaying] = useState('')

    const url = 'users/plesh/events/'
    const [theme, themeLoading, themeError] = useDocument(firestore.collection('users/plesh/events/' + id + '/themes').doc(event?.data().theme))

    useEffect(() => {
        getIdPlaying()
    }, [])

    useEffect(() => {

    }, [displayName]);

    function getIdPlaying() {
        firestore.collection(url).doc(id).onSnapshot(s => {
            let e = s.data().elementPlaying
            let g = s.data().playing
            setIdPlaying(e)
            setEventPlaying(g)
        })
    }

    return (
        <Box>
            <Stack direction={"column"} justifyContent={"start"} alignContent={"center"} sx={{height: '75vh', py: 2}}
                   spacing={4}>
                <Box sx={{height: '4rem'}}></Box>
                {event.data().logo && <LogoBox
                    logo={event.data().logoUrl ? event.data().logoUrl : 'https://storage.googleapis.com/2ndstage-public/logos/logo-2nd.png'}/>}
                {
                    event.data().autenticazione &&
                    <DisplayName displayName={displayName} setDisplayName={setDisplayName}/>
                }
                {/*event.data().agenda && <Box>
                    <Typography sx={{mx: 2}} component={"div"} variant={"h5"} fontWeight={"bold"}
                                style={{borderBottom: '1px solid white'}}>
                        {event.data().titoloAgenda}
                    </Typography>
                    <Agenda event={event}/>
                </Box>*/}
            </Stack>
            {loading ?
                <Box sx={{textAlign: 'center'}}>
                    <CircularProgress/>
                </Box>
                :
                event.data().applausometro ?
                    event.data().autenticazione
                    ?
                    (displayName !== '' && displayName) &&
                        <Buttons url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying} user={user}/>
                    :
                    <Buttons url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying} user={user}/>
                :
                    <></>
            }
            {(theme && !themeLoading && !themeError && !loading) ?
                event.data().autenticazione
                    ?
                    (displayName !== '' && displayName) &&
                        <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                    :
                    <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                :
                <></>
            }
        </Box>

    )
}
